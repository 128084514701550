body.dark-theme {
  .modal-content {
    background-color: $theme-dark;

    .light-gray-bg {
      background: transparent;
    }

    button {
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}

.modal-content {
  .light-gray-bg {
    background: transparent;
  }
}

//body table.table-with-action-buttons {
//  margin-bottom: 80px;
//  th.action, td.action {
//    text-align: center;
//    width: 190px;
//
//    .icon-more{
//      cursor:pointer;
//    }
//    .dropdown-menu {
//      left: 50% !important;
//      transform: translateX(-50%) !important;
//      top: 25px !important;
//      cursor:pointer !important;
//      &:after {
//        left: calc(50% + 10px);
//      }
//    }
//
//  }
//}
body table.table-with-action-buttons {
  margin-bottom: 80px;

  th.action,
  td.action {
    text-align: center;
    width: 190px;

    .icon-more {
      cursor: pointer;
    }

    .dropdown-menu {
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: 25px !important;
      cursor: pointer !important;

      &:after {
        left: calc(50% + 10px);
      }
    }
  }

  td.action {
    position: relative;

    .icon-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 35%;
    }

    .action-button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -10%;
    }

    .action-button button img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.activity-log-table {
  padding: 30px 0;

  &.table-block {
    table {
      thead {
        tr {
          border-bottom: none !important;

          th {
            &:first-child {
              padding-left: 30px;
              @media (max-width: 991px) {
                padding-left: 15px;
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            background: rgba(244, 244, 245, 0.23);
            height: 74px;
            border-bottom: 4px solid #fff;

            &:first-child {
              padding-left: 30px;
              @media (max-width: 991px) {
                padding-left: 15px;
              }
            }
          }
        }
      }
    }
  }
}

body {
  &.dark-theme {
    .activity-log-table {
      &.table-block {
        table {
          tbody {
            tr {
              td {
                background: rgba(3, 5, 10, 0.23);
                border-bottom: 4px solid #0e1226;
              }
            }
          }
        }
      }
    }
  }
}

.table-block {
  table {
    tbody {
      tr {
        td {
          a:not(.dropdown-item) {
            color: $black;
          }
        }
      }
    }
  }
}

//.heading-block .heading-content {
//  padding-right: 20px;
//  display: flex;
//  align-items: center;
//}
//.heading-block .heading-content h2 {
//  padding-right: 30px;
//}
body .heading-block {
  display: flex;
  align-items: center;
  padding: 10px 30px 5px 30px;
  @media (max-width: 991px) {
    padding: 10px 15px 5px 15px;
  }

  .heading-content {
    padding-right: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      padding-right: 0;
    }

    h2 {
      padding-right: 20px;
    }

    .icon-edit {
      color: $black;
      font-size: 16px;
    }

    .display-text-field {
      @media (min-width: 768px) {
        padding-right: 20px;
      }

      .form-group {
        margin-bottom: 0;
      }

      input {
        padding: 5px 0;
        @media (min-width: 768px) {
          width: 300px;
        }
      }
    }

    .btn-theme {
      & + .btn-theme {
        margin-left: 12px;
      }
    }

    &.edit-on {
      padding-right: 0;
      width: 100%;

      .display-text-field {
        width: 100%;

        input {
          width: 100%;
        }
      }

      @media (max-width: 767px) {
        display: block;
        width: 100%;
        margin-bottom: 5px;
      }

      & + .breadcrumb-block {
        display: none;
      }
    }
  }
}

body .breadcrumb-block {
  span {
    color: $black;
    font-family: $circular-book;

    a {
      color: $black;
    }

    & + span {
      &:before {
        content: "/";
        margin: 0 10px 0 7px;
      }
    }

    &:last-child {
      font-family: $circular-bold;
    }
  }
}

body.dark-theme {
  .table-block {
    table {
      tbody {
        tr {
          td {
            a:not(.dropdown-item) {
              color: $white;
            }
          }
        }
      }
    }
  }

  .heading-block {
    .heading-content {
      .icon-edit {
        color: $white;
      }
    }
  }

  .breadcrumb-block {
    span {
      color: $white;

      a {
        color: $white;
      }
    }
  }
}

.pop-up-message {
  .media {
    .media-img {
      width: 36px;

      svg {
        width: 100%;
      }
    }
  }

  .media-body {
    h6 {
      font-size: 16px;
      margin-bottom: 4px;
      color: $black;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
      color: $black;
    }
  }
}

.badge {
  padding: 5px 10px !important;
  min-width: 90px;
}

.mx-100 {
  max-width: 100% !important;
}

.card-details-block {
  .button-block {
    button {
      min-width: 160px;
    }
  }
}

body {
  .header-block {
    .header-block-wrapper {
      @media (min-width: 1921px) {
        max-width: 100%;
        padding: 0 100px;
      }

      .search-bar {
        @media (min-width: 1800px) {
          width: calc(100% - 200px);
        }
      }

      .button-wrapper {
        @media (min-width: 1800px) {
          position: static;
        }
      }
    }
  }

  .single-notification {
    .single-notification-wrapper {
      @media (min-width: 1921px) {
        max-width: 100%;
        padding: 0 100px;
      }

      .notification-text {
        @media (min-width: 1800px) {
          width: calc(100% - 200px);
        }
      }

      .button-wrapper {
        @media (min-width: 1800px) {
          position: static;
        }
      }
    }
  }

  .boxed-block {
    @media (min-width: 1921px) {
      max-width: 100%;
      padding: 0 100px;
    }
  }

  .content-menu {
    padding: 28px 30px 10px 30px;
    @media (max-width: 991px) {
      padding: 22px 15px 10px 15px;
    }
  }
}

.login-page-block {
  &.register-page-block {
    min-height: 100%;

    .form-block {
      padding: 60px 15px;
      @media (max-width: 1440px) {
        padding: 30px 15px;
      }

      h4 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 20px;
      }

      .form-control {
        & + .form-control {
          margin-top: 10px;
        }
      }

      small {
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        display: block;
      }

      p {
        font-size: 15px;
        line-height: 20px;
        text-align: center;

        a {
          color: $theme-blue;
        }
      }

      .recaptcha {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      .recaptcha .error-msg {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
}

body .wp-password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  input {
    padding-right: 45px;
  }

  a {
    padding: 0 0 0 10px;
  }

  .copy {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
}

.login-page-block {
  &.register-page-block {
    .form-block {
      .company-question {
        padding: 10px 30px;
        margin-bottom: 30px;

        p {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-bottom: 0;

          button {
            margin: 10px 0;
          }
        }
      }
    }
  }
}

.add-new-card {
  .company-question {
    padding: 10px 30px;
    margin-bottom: 30px;
    background: $gray1;

    p {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 0;

      button {
        margin: 10px 0;
      }
    }
  }
}

body .card-info-wrapper .StripeElement {
  border-bottom: 2px solid #d1d1d1;
  padding: 6px 12px;
}

.master-card {
  height: 55px;
  width: 45px;
}

.stripe-card {
  height: 55px;
  width: 45px;
}

.visa-card {
  height: 55px;
  width: 45px;
}

.payment-method {
  color: #7f7c7c;
}

.listing-table-alt {
  table {
    tbody {
      tr {
        td {
          background: transparent;
        }

        & + tr {
          border-top: none;
        }
      }
    }
  }
}

.card-detail-container {
  .payment-method {
    padding: 0 12px;
  }
}

hr.spacer {
  border: none;
  margin: 10px 0 0 0;
}

.custom-mt {
  margin-top: 5px;
}

.add-card-modal {
  .master-card,
  .stripe-card,
  .visa-card {
    height: auto;
    width: 30px;
  }
}

//.coming-soon{
//  text-align: right;
//  color:red;
//}

.loading-domain {
  text-align: center;
}

.coupon-success-message {
  text-align: right;
  //margin-left:50px;
  color: green;
}

body .delete-site {
  margin-bottom: 15px;

  .delete-site-name {
    background: transparentize($gray1, 0.77);
    display: flex;
    height: 60px;
    align-items: center;
    padding: 0 20px 0 22px;
  }

  .delete-site-form {
    background: transparentize($gray1, 0.77);
    height: 110px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 22px;
  }
}

body.dark-theme {
  .custom-popup {
    .form-group {
      label {
        color: #fff;
      }

      input {
        &::-webkit-input-placeholder {
          color: #fff;
        }

        &:-ms-input-placeholder {
          color: #fff;
        }

        &::placeholder {
          color: #fff;
        }
      }
    }
  }
}

.input-div {
  position: relative;

  span.icon-checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

/*.ssl-form{
.form-group {
  textarea {
    height: 200px;
  }
}
}*/

.custom-popup {
  &.confirmation-popup {
    max-width: 860px;
    border-radius: 10px;

    .modal-body {
      padding: 0px 40px;
    }
  }
}

.custom-popup {
  &.ssl-popup {
    max-width: 860px;
    border-radius: 10px;

    .modal-body {
      //padding: 0px 40px;
      padding-top: 10px 5px 40px;
    }
  }
}

.onclick {
  cursor: pointer;
}

.tools-table {
  .redirect-rules {
    padding: 30px;
  }
}

.migration-page-block {
  .title-block {
    margin-bottom: 50px;

    span {
      font-size: 24px;
    }
  }

  .form-block {
    max-width: 796px;

    //@media (max-width: 1680px) {
    //  max-width: 675px;
    //}
  }

  .migration-nav-menu {
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 2px;
      background: #0d22e3;
      display: block;
      margin: 0 auto;
      left: 50%;
      top: 12px;
      position: absolute;
      right: 0;
      transform: translateX(calc(-50% - 8px));
    }

    ul {
      &.migration-nav-link {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          position: relative;

          &::before {
            content: "";
            width: 26px;
            height: 26px;
            display: block;
            border: 2px solid $theme-blue;
            background: $white;
            border-radius: 50px;
            margin: 0 auto;
          }

          a {
            font-size: 15px;
            font-family: $circular-book;
            color: $black3;
          }

          &.current,
          &.completed {
            &::before {
              background: $theme-blue;
            }

            a {
              font-family: $circular-bold;
            }
          }
        }
      }
    }
  }

  form {
    .form-row {
      > .col,
      > [class*="col-"] {
        padding: 0 15px;
      }
    }

    .form-group {
      label {
        padding: 0 10px;

        //@media (max-width: 1680px) {
        //  margin-bottom: 0;
        //}
      }

      .form-control {
        background: transparent;
        padding: 0 10px;
        border-bottom: 2px solid $gray5;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $theme-blue;
        }
      }
    }
  }

  .steps-buttons {
    button {
      margin-bottom: 15px;
      @media (max-width: 767px) {
        min-width: 140px;
      }
      @media (max-width: 360px) {
        min-width: 100%;
        margin-left: 0 !important;
      }
    }
  }

  //@media (max-width: 767px) {
  //  height: auto !important;
  //  overflow: auto !important;
  //}
}

.migration-page-block {
  .custom-form-block {
    padding: 60px;
    @media (max-width: 767px) {
      padding: 30px 40px;
    }

    .migration-nav-menu {
      &:before {
        transform: translateX(-50%);
      }

      ul.migration-nav-link {
        overflow: visible;

        li {
          padding-bottom: 35px;

          a {
            position: absolute;
            bottom: 0;
            min-width: max-content;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    .steps-content {
      .form-row {
        margin: 0 -15px;
      }
    }

    .steps-buttons {
      .btn-theme {
        padding: 11px 35px;
      }
    }
  }
}

.loader-position {
  position: absolute;
  right: 20px;
}

.sm-loader-position {
  position: absolute;
  //right:20px
}

.custom-popup {
  .redirect-form {
    .form-group {
      input {
        border: 1px solid #e6e6e6;
        padding: 0 10px;
      }
    }

    .has-error input,
    .has-error select,
    .has-error textarea {
      border: 1px solid red !important;
    }

    .form-check {
      .has-error {
        color: red;
      }
    }
  }
}

.dropdown-menu {
  min-width: 8rem;
}

.email-verify {
  .email-verify-message {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 30em;
    height: 18em;
    margin-top: -9em;
    margin-left: -15em;

    h2 {
      color: green;
      text-align: center;
    }

    span {
      //margin-left: 80px;
    }
  }

  .not-logged-in {
  }
}

.cross-icon {
  height: 20px;
  width: 10px;
}

.error-cross-image {
  height: 80px;
  margin-top: 90px;
}

.add-domain {
  cursor: pointer;
}

.load-more {
  text-align: center !important;
}

/*
.domain-form-input-wrapper {
  position: relative;

  input {
    padding-right: 10px;
  }

  .reset {
    position: absolute;
    color: $theme-blue;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}*/
.custom-popup {
  &.domainPopup {
    input {
      border: none;
      border-bottom: solid 2px #d1d1d1;
      border-radius: 0;
      background: transparent;

      &::placeholder {
        color: transparentize(#383838, 0.86);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: transparentize(#383838, 0.86);
      }

      &::-ms-input-placeholder {
        color: transparentize(#383838, 0.86);
      }
    }

    input:focus {
      outline: none;
      border-bottom: solid 2px #0d21e3 !important;
    }
  }
}

.wistia_responsive_padding {
  padding: 60.25% 0 0 0;
  position: relative;
}
.wistia_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.payment-notice {
  margin-top: 30px;
  .notice {
    background: #ee49f3;
    color: white;
    padding: 5px 5px 1px;
    border-radius: 12px 12px 0px 0px;
    justify-content: center;
  }
  .payment-notice-message {
    background: transparent;
    padding: 20px;
    @media (max-width: 991px) {
      padding: 20px 15px;
    }
    p {
      margin-left: 25px;
      display: flex;
      padding: 5px 10px 2px;
    }
  }
}

.btn-theme.btn-text {
  color: #0d21e3;
  border: 0;
  padding: 0;
  min-width: auto;
  position: relative;
  height: 39px;
  line-height: 39px;
}

.btn-theme.btn-text:hover {
  background: transparent;
}

.btn-text-wrapper {
  margin-bottom: 30px;
  padding: 4px 0;
}

.btn-theme.btn-text:before {
  content: "";
  position: absolute;
  background: transparent;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 8px;
  transition: 0.3s all ease-in-out;
}

.btn-theme.btn-text:hover:before {
  background: #0d21e3;
  width: 100%;
}

button.btn-theme.btn-text:after {
  content: "\e905";
  font-family: "kepler-customer" !important;
  margin-left: 7px;
}

button.btn-theme.btn-text.all-copied:after {
  content: "\e900";
}

.login {
  &.newLogin {
    background: linear-gradient(
      120deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(1, 88, 244, 1) 100%
    );

    .login-page-block {
      margin: 30px;
      background-color: #0b0f35;
      border-radius: 30px;
      height: calc(100vh - 60px);
      color: #fff;

      label,
      input {
        color: inherit !important;

        &::placeholder {
          color: transparentize(#fff, 0.44) !important;
        }
      }

      .form-block {
        justify-content: space-evenly;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 30px;

        @media (max-width: 767px) {
          padding: 30px 15px;
        }

        .button-group {
          justify-content: center;

          button,
          a {
            @media (max-width: 991px) {
              min-width: 120px;
            }

            &:hover {
              background-color: #fff;
              color: #000;
            }
          }
        }

        .forgot-password {
          border: 1px solid transparentize(#fff, 0.84);
          padding: 5px 10px;
          color: transparentize(#fff, 0.84) !important;
          border-radius: 5px;
          text-decoration: none;

          @media (max-width: 767px) {
            font-size: 16px;
            line-height: normal;
          }
        }
      }

      .upper-block {
        img {
          @media (max-width: 1199px) {
            height: 35px;
          }
        }

        h3 {
          color: #fff;
        }
      }
    }

    .img-block {
      display: flex;
      justify-content: center;
      height: 100vh;
      overflow: hidden;

      @media (max-width: 767px) {
        display: none;
      }

      img {
        transform: scale(1.1);
        object-fit: contain;

        @media (max-width: 1199px) {
          transform: none;
        }
      }
    }

    &.registerForm {
      .form-block {
        padding: 0px;
        @media (max-width: 1600px) {
          padding: 30px;
        }
        .form-row {
          @media (max-width: 1600px) {
            display: block;
          }
          .col-md-6 {
            @media (max-width: 1600px) {
              max-width: 100%;
            }
          }
        }
      }
      p {
        text-align: center;
      }
    }
  }

  &.forgot-block {
    .login-page-block {
      .form-block {
        justify-content: center;

        .upper-block {
          margin-bottom: 100px;
        }
      }
    }
  }
}
