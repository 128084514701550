/* login page */

.login {
  .content-area-wrapper {
    padding-bottom: 0;
    .content-area {
      padding: 0;
    }
  }
}

.login-page-block {
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;

  &.page_login {
    .form-group {
      label {
        padding: 0 10px;
      }
      .form-control {
        background: transparent;
        padding: 0 10px;
        border-bottom: 2px solid $gray5;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $theme-blue;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .bg-img {
    @media (max-width: 767px) {
      display: none;
    }
    .left-img {
      left: 0;
      bottom: 0;
      width: 25%;

      @media (max-width: 1680px) {
        width: 23%;
      }
    }

    .right-img {
      top: 0;
      right: 0;
      width: 28%;

      @media (max-width: 1680px) {
        width: 23%;
      }
    }
    .left-img,
    .right-img {
      position: fixed;
      //opacity: .06;
    }
  }

  .form-block {
    max-width: 480px;
    padding: 30px 15px;
    margin: auto;
    width: 100%;
    z-index: 1;

    .upper-block {
      i {
        font-size: 47px;
        color: $black;
      }
      h3 {
        color: #2b2b2b;
        @media (min-width: 1200px) {
          font-size: 34px;
          line-height: 46px;
        }
      }
    }
    label {
      font-size: 18px;
      line-height: 26px;
      color: #383838;
      font-family: $circular-book;
      padding: 0;

      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    .form-control {
      height: 50px;
      background: #fcfcfc;
      border-radius: 3px;
      border: none;
      font-size: 16px;

      &::placeholder {
        color: transparentize(#383838, 0.86);
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: transparentize(#383838, 0.86);
      }

      &::-ms-input-placeholder {
        color: transparentize(#383838, 0.86);
      }
    }
  }
  .btn-theme {
    min-width: 162px;
  }
}

/* listing table */

.listing-table {
  .listing-cache {
    display: flex;
    justify-content: space-between;

    .clear-cache {
      background: transparentize($gray1, 0.77);
      width: 49.5%;
      display: flex;
      height: 120px;
      align-items: center;
      padding: 0 20px 0 40px;

      .media {
        margin-right: 20px;
        img {
          width: 40px;
        }
      }

      .media-body {
        span {
          display: block;
          opacity: 0.37;
          font-size: 10px;
          line-height: 14px;
        }
        h4 {
          margin-bottom: 0;
        }
      }
    }
  }

  .listing-ssl {
    display: flex;
    justify-content: space-between;

    .custom-ssl {
      background: transparentize($gray1, 0.77);
      width: 49.5%;
      display: flex;
      height: 120px;
      align-items: center;
      padding: 0 20px 0 40px;

      .media {
        margin-right: 20px;
        img {
          width: 40px;
        }
      }

      .media-body {
        span {
          display: block;
          opacity: 0.37;
          font-size: 10px;
          line-height: 14px;
        }
        h4 {
          margin-bottom: 0;
        }
      }
    }
  }

  .ssl-form {
    margin-top: 10px;
    background: transparentize($gray1, 0.77);
    width: 100%;
    padding: 50px 20px 50px 40px;
  }

  .add-new-rule {
    margin-top: 10px;
    background: transparentize($gray1, 0.77);
    width: 100%;
    padding: 50px 20px 50px 40px;

    .add-new-rule-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      p {
        width: 80%;
      }

      a {
        text-decoration: underline;
        color: $black;
      }
    }
  }

  .add-new-ssl-rule {
    margin-top: 10px;
    background: transparentize($gray1, 0.77);
    width: 100%;
    padding: 50px 20px 50px 40px;

    .add-new-rule-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      p {
        width: 80%;
      }

      a {
        text-decoration: underline;
        color: $black;
      }
    }
  }

  .rule-urls {
    padding-bottom: 15px;

    .dropdown-menu {
      float: none;
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: calc(100% + 7px) !important;
      &:after {
        left: calc(50% + 10px);
      }
    }

    .url-table {
      width: 100%;
      min-width: 650px;
      margin-top: 20px;
      border-collapse: collapse;

      tbody {
        tr {
          background: transparentize($gray1, 0.77);

          .url-table-heading {
            border-bottom: 1px solid #64646454;
            padding: 35px 20px 10px 40px;
            margin-bottom: 40px;
          }

          .url-table-body {
            padding: 30px 20px 40px 40px;

            span {
              font-size: 18px;
              cursor: pointer;
            }
          }

          #actions {
            text-align: center;
          }
          & + .url-table-col {
            border-top: 0px solid $theme-default;
          }
        }
      }
    }
  }

  .ignore-rules {
    margin-top: 10px;
    background: transparentize($gray1, 0.77);
    width: 100%;
    padding: 50px 20px 50px 40px;

    .ignore-rules-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      p {
        width: 50%;
      }

      a {
        text-decoration: underline;
        color: $black;
      }
    }
  }

  .migration-rules {
    margin-top: 10px;
    background: transparentize($gray1, 0.77);
    width: 100%;
    padding: 50px 20px 50px 40px;

    .migration-rules-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      p {
        width: 80%;
      }

      a {
        text-decoration: underline;
        color: $black;
      }
    }
  }

  .ignore-urls {
    padding-bottom: 15px;

    .dropdown-menu {
      float: none;
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: calc(100% + 7px) !important;
      &:after {
        left: calc(50% + 10px);
      }
    }

    .url-table {
      width: 100%;
      min-width: 650px;
      margin-top: 20px;
      border-collapse: collapse;

      tbody {
        tr {
          background: transparentize($gray1, 0.77);

          .url-table-heading {
            border-bottom: 1px solid #64646454;
            padding: 35px 20px 10px 40px;
            margin-bottom: 40px;
          }

          .url-table-body {
            padding: 30px 20px 40px 40px;

            span {
              font-size: 18px;
              cursor: pointer;
            }
          }

          #actions {
            text-align: center;
          }
          & + .url-table-col {
            border-top: 0px solid $theme-default;
          }
        }
      }
    }
  }

  .icon-minus {
    font-size: 27px;
    color: $theme-blue;
  }

  .btn-theme {
    min-width: 160px;
  }
  .btn-theme-disabled {
    min-width: 160px;
  }
}

table {
  width: 100%;
  min-width: 650px;
  tbody {
    tr {
      td {
        font-size: 15px;
        line-height: 21px;
        padding: 20px 15px 25px;
        background: transparentize($gray1, 0.77);

        &:last-child {
          //text-align: right !important;
        }

        @media (min-width: 992px) {
          &:first-child {
            padding-left: 30px;
          }
          &:last-child {
            padding-right: 30px;
          }
        }
        a {
          color: inherit;
        }
        h4 {
          font-family: $circular-book;
          font-size: 20px;
        }

        h6 {
          font-family: $circular-book;
          margin-bottom: 4px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            opacity: 0.37;
            font-size: 15px;
            line-height: 21px;
            a {
              color: $black;
            }
          }
        }
        .media {
          .media-img {
            width: 45px;
            svg {
              width: 100%;
            }
          }
          .media-body {
            h4 {
              margin-bottom: 0;
            }
          }
        }
        p {
          font-size: 12px;
          line-height: 16px;
          @media (min-width: 1200px) {
            padding: 0 12%;
          }
        }
        span {
          display: block;
          opacity: 0.37;
          font-size: 10px;
          line-height: 14px;
          &.title {
            font-size: 10px;
            line-height: 14px;
            opacity: 0.2;
            margin-bottom: 10px;
          }
          &.big-span {
            font-size: 14px;
            line-height: 20px;
          }
          &.big-span-blue {
            color: blue;
            opacity: 0.9 !important;
          }
        }
        &.close-button {
          width: 110px;
          padding: 10px 30px;
          text-align: center;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            background: $gray4;
            left: 0;
            width: 1px;
            height: 80%;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      & + tr {
        border-top: 20px solid $theme-default;
      }
      .table-view-block {
        width: 300px;
        text-align: right;
      }
    }
  }
}

/* table block */

.table-block {
  table {
    width: 100%;
    min-width: 650px;
    thead {
      tr {
        border-bottom: 1px solid transparentize($gray, 0.75);
        th {
          font-size: 15px;
          line-height: 21px;
          font-family: $circular-bold;
          padding: 5px 15px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 15px;
          line-height: 21px;
          padding: 15px;
          &:first-child {
            padding-left: 0;
          }
          span {
            font-size: 12px;
            line-height: 16px;
          }
          &.buttons-block {
            width: 130px;
            min-width: 130px;
          }
          button.dropdown-toggle {
            @include transition(0.3s all ease);
            &[aria-expanded="true"],
            &:hover {
              background: $theme-blue;
              color: $white;
              border-color: $theme-blue;
            }
          }
          .icon-wrapper {
            .icon-checkmark {
              margin-right: 20px;
              color: $theme-blue;
            }
            a {
              color: $gray;
            }
          }
        }
      }
    }
  }
}

/* content container */
.content-container {
  margin-top: 30px;
  padding: 40px 60px;
  @media (max-width: 1199px) {
    margin-top: 20px;
    padding: 30px 30px;
  }

  @media (max-width: 991px) {
    padding: 20px 15px;
  }

  .nav-content-header {
    display: flex;
    justify-content: space-between;

    .nav-content-header-right {
      display: flex;

      .nav-content-header-search {
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-right: 40px;
        width: 250px;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          background: none;
          border-top: none;
          border-left: none;
          border-right: none;
          border-bottom: solid 0.3px #0e122628;
          font-size: 0.8rem;
          padding: 5px 15px;
          outline: none;
        }
      }

      .nav-content-header-refresh {
        display: flex;
        padding-bottom: 20px;
        margin-bottom: 20px;
        align-items: center;
        cursor: pointer;

        p {
          margin-bottom: 0;
          font-size: 13px;
        }

        #refresh {
          margin-left: 10px;
          width: 15px;
        }
      }
    }
  }

  .content-label {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .content-info {
    font-size: 16px;
    line-height: 18px;
    background: $gray3;
    padding: 15px 40px 15px 15px;
    margin-bottom: 30px;
    position: relative;
    font-family: $courier;

    @media (max-width: 767px) {
      padding: 12px 40px 11px 15px;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .action-btn-copy {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      margin: 0 !important;

      button {
        background: transparent;
        height: 30px;
        line-height: 30px;
        width: 40px;
        border-radius: 0;
        border: none;
        border-left: 1px solid transparentize($theme-dark, 0.91);

        span[class*="icon-"] {
          font-size: 16px;
          color: $theme-blue;
        }
      }
    }
  }

  hr {
    border-top: 1px solid transparentize($gray, 0.87);
    margin: 30px 0;
  }

  .text-link-block {
    padding: 25px 0;

    p {
      margin-bottom: 20px;
      max-width: 775px;
    }

    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;
      p {
        padding-right: 50px;
        margin-bottom: 0;
      }
    }
  }

  .content-details {
    @media (min-width: 1440px) {
      display: flex;
      .content-details-wrapper {
        padding-right: 60px;
        width: 100%;
      }
    }

    .btn-theme {
      min-width: 170px;
    }
  }

  .tab-content {
    .cache-content {
      background-color: #ffffff;
      padding: 25px 20px;
      margin-bottom: 10px;
    }
  }

  .tab-info-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 767px) {
      display: block;
    }

    .tab-info-block {
      padding-right: 40px;
      @media (max-width: 767px) {
        padding-right: 0;
      }

      p {
        font-size: 15px;
        line-height: 21px;
        margin-bottom: 0;
      }
    }

    .tab-backup-button {
      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }

  .text-block {
    max-width: 775px;

    p {
      margin-bottom: 4px;
    }

    small {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .organization-section {
    display: flex;
    justify-content: space-between;

    .organization-card {
      background-color: $white;
      border: 1px solid transparentize($gray, 0.87);
      border-radius: 5px;
      width: 49%;
      padding: 35px 30px;
      position: relative;

      .card-text {
        h3 {
          margin-bottom: 0;
        }

        p {
          color: $gray;
          font-size: 12px;
        }
      }

      .card-button {
        display: flex;
        justify-content: flex-end;

        button {
          border-radius: 5px;
          width: 140px;
        }
      }

      .card-tag {
        position: absolute;
        top: 17px;
        right: 30px;
        border: $theme-blue solid 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;

        p {
          margin-bottom: 0;
          padding: 6px 8px;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }
  }

  .users-section {
    p {
      margin-bottom: 0;
    }

    button {
      width: 130px;
    }

    .user-card {
      padding: 20px 30px;
      border: 1px solid transparentize($gray, 0.87);
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #1a293b;
    }

    .new-user {
      display: flex;
      justify-content: flex-end;
      padding: 20px 30px 0 30px;
    }

    .add-new-user-section {
      display: flex;
      justify-content: space-between;

      form {
        display: flex;
        flex-direction: column;
        width: 45%;

        label {
          margin-bottom: 0;
          font-size: 14px;
          padding: 0 10px;
        }

        input {
          margin-bottom: 15px;
          border: none;
          border-bottom: 2px solid transparentize($gray, 0.87);
          background-color: transparentize($gray, 0.999);
          padding: 10px 10px 7px 10px;
          font-size: 14px;
          outline: none;
        }

        #important-info {
          border-bottom: 2px solid transparentize($theme-blue, 0.25);
        }

        .create-user-button {
          margin-top: 25px;
        }
      }

      .add-new-user-description {
        display: flex;
        flex-direction: column;
        width: 49%;
        margin-top: 50px;
      }
    }
  }
}

/* overview block */

//.overview-block {
//  display: flex;
//  flex-wrap: wrap;
//  background: $white;
//  padding: 10px 0;
//  .single-overview {
//    padding: 5px 30px;
//    border-right: 1px solid $gray2;
//    max-width: 320px;
//    margin: 10px 0;
//    &:last-child {
//      border-right: none;
//    }
//    h3 {
//      margin-bottom: 0;
//      a {
//        color: $black;
//        text-decoration: underline;
//      }
//    }
//  }
//}

.overview-block {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  padding: 10px 0;
  align-items: center;

  .single-overview {
    padding: 5px 30px;
    border-right: 1px solid $gray2;
    max-width: 320px;
    margin: 10px 0;

    @media (min-width: 768px) {
      flex: 1 1;
    }

    &:last-child {
      border-right: none;
    }
    h3 {
      margin-bottom: 0;
      a {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  .pay-now-btn {
    padding: 5px 30px;
    max-width: 320px;
    margin: 10px 0;
    button {
      min-width: 160px;
    }
  }
}

/* card details */

.single-card-details {
  border: 1px solid transparentize($gray, 0.77);
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: $black1;
  @media (max-width: 767px) {
    display: block;
  }
  .card-info {
    @media (min-width: 768px) {
      padding-right: 30px;
    }
    h6 {
      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 28px;
      }
      span {
        font-family: $circular-book;
        margin-left: 20px;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .button-block {
    @media (max-width: 767px) {
      margin-top: 20px;
    }
    a {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .icon-more {
        color: $gray;
      }
      &.btn-theme {
        min-width: 162px;
      }
    }
    .icon-more {
      cursor: pointer;
    }
    .dropdown-menu {
      left: 50% !important;
      transform: translateX(-50%) !important;
      top: 25px !important;
      cursor: pointer !important;
      &:after {
        left: calc(50% + 10px);
      }
    }
  }
  & + .single-card-details {
    margin-top: 20px;
  }
}

/* shadowed box */

.shadowed-box {
  border-radius: 9px;
  box-shadow: 0 3px 6px transparentize($black, 0.66);
  padding: 50px;
  @media (max-width: 1199px) {
    padding: 30px;
  }

  @media (max-width: 767px) {
    padding: 15px;
  }
  p {
    & + p {
      margin-top: 0;
    }
  }

  h2 {
    margin-bottom: 0;
  }
}

//form validation error
.has-error {
  input,
  textarea,
  select {
    border-bottom: 1px solid red !important;
  }
  .error-msg {
    color: red;
  }
}

.error-msg {
  color: red;
}

.imgPreview img {
  width: 120px;
  height: 120px;
}

.activity-log-table {
  table {
    thead {
      tr {
        th {
          &:last-child {
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:last-child {
            text-align: center;
          }
        }
      }
    }
  }
}

/*  content message block */

.content-message-block {
  padding: 10px 0;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  h6 {
    font-size: 11px;
    line-height: 17px;
    font-family: $circular-medium;
  }
  h2 {
    margin-bottom: 15px;
    span {
      color: #ffa700;
    }
  }
  .button-wrapper {
    a {
      min-width: 162px;
    }
    &.inline-buttons {
      a {
        margin: 0 6px 12px;
      }
    }
  }
}

@media (min-width: 992px) {
  .custom-button-td {
    padding-right: 60px !important;
  }
}

.custom-button-td {
  width: 190px;
}
