a {
  &.forgot-password {
    color: $gray6;
    text-decoration: underline;
  }
}

.forget_password {
  form {
    .form-row {
      > .col,
      > [class*="col-"] {
        padding: 0 15px;
      }
    }
    .form-group {
      label {
        padding: 0 10px;
      }
      .form-control {
        background: transparent;
        padding: 0 10px;
        border-bottom: 2px solid $gray5;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $theme-blue;
        }
      }
    }
  }
}