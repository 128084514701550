* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  -webkit-overflow-scrolling: touch;
}

html {
  font-size: 16px;
  overflow-x: hidden;

  &.menu-active {
    overflow: hidden;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  margin: 0;
  overflow-x: hidden;
  position: relative;
  color: $black;
  background: $theme-default;
  font-family: $circular-book;

  //generate margin and padding
  @include getMarginPadding(".mt-", "margin-top");
  @include getMarginPadding(".mb-", "margin-bottom");
  @include getMarginPadding(".pt-", "padding-top");
  @include getMarginPadding(".pb-", "padding-bottom");
  @include getMarginPadding(".pl-", "padding-left");
  @include getMarginPadding(".pr-", "padding-right");

  &.active,
  &.overflow-hidden {
    @media (max-width: 991px) {
      overflow: hidden;
      .content-area-wrapper {
        position: relative;
        transition: 0.3s ease all;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1000vh;
          background: transparentize($gray2, 0.52);
          transition-delay: 0.3s;
        }
      }
    }
  }
  &.menu-active {
    @media (max-width: 991px) {
      overflow: hidden;
      .content-area-wrapper {
        transition: 0.3s ease all;
        &:after {
          content: "";
          background: transparentize($black, 0.2);
          top: 0;
          height: 1000%;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: 8;
          transition-delay: 0.3s;
        }
      }
      #root {
        left: 220px;
        position: relative;
        transition: 0.3s ease all;
        min-height: 100vh;
      }
      .fixed-footer {
        width: calc(100% - 220px);
      }
    }
  }

  &.menu-active {
    &:not(.dark-theme) {
      .content-area-wrapper {
        &:after {
          background: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }

  .d-dark {
    display: none;
  }

  &.dark-theme {
    .d-light {
      display: none;
    }

    .d-dark {
      display: inline-block;
    }
  }
}

.container,
.container-fluid {
  @include transition(0.3s ease all);
}

a {
  text-decoration: none;
  @include transition(0.3s ease all);

  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $circular-bold;
}

h1 {
  font-size: 60px;
  line-height: 65px;
}

h2 {
  font-size: 35px;
  line-height: 42px;

  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 30px;
  }
}

h3 {
  font-size: 27px;
  line-height: 37px;

  @media (max-width: 1199px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 26px;
  }
}

h4 {
  font-size: 26px;
  line-height: 30px;

  @media (max-width: 1199px) {
    font-size: 23px;
    line-height: 28px;
  }

  @media (max-width: 767px) {
    font-size: 19px;
    line-height: 24px;
  }
}

h5 {
  font-size: 22px;
  line-height: 28px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 24px;
  }
}

h6 {
  font-size: 18px;
  line-height: 24px;

  @media (max-width: 767px) {
    font-size: 17px;
    line-height: 22px;
  }
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

p {
  font-size: 16px;
  line-height: 22px;
  font-family: $circular-book;
  margin-bottom: 8px;

  strong {
    font-family: $circular-bold;
  }

  & + p {
    margin-top: 20px;
  }

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 20px;
  }
}

body {
  .sidebar-wrapper {
    max-width: 280px;
    width: 280px;
    padding: 0;
    position: fixed;
    left: 0;
    height: 100%;
    top: 0;
    z-index: 2;
    transition: 0.3s ease all;

    &:not(.not-loggedIn) {
      .sidebar {
        .sidebar-block {
          .sidebar-title-block {
            @media (max-width: 991px) {
              display: none;
            }
          }
        }
      }
    }

    .sidebar {
      .sidebar-block {
        background: $gray1;
        position: relative;
        height: 100%;

        @media (min-width: 992px) {
          height: 100vh;
        }

        .sidebar-title-block {
          // background: $theme-blue;
          background: #000;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;
          height: 64px;

          .sidebar-logo-block {
            display: flex;
            align-items: center;

            a {
              display: flex;
              align-items: center;
            }
            span {
              font-size: 20px;
              color: $white;
            }
            p {
              color: $white;
              margin-bottom: 0;
              font-size: 15px;
              margin-left: 10px;
            }
          }

          .theme-switch {
            cursor: pointer;
          }
        }

        .sidebar-menu-block {
          background: #0d21e3;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: calc(100% - 64px);
          padding: 40px 15px 20px;
          // overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }
          @media (max-width: 991px) {
            height: calc(100% - 60px);
          }
          .main-menu {
            .main-menu-wrapper {
              span {
                cursor: default;
                font-size: 8px;
                color: #fff;
                @media (min-width: 992px) {
                  padding-left: 30px;
                }
              }
              ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;

                @media (min-width: 992px) {
                  padding-left: 30px;
                }

                li {
                  a {
                    font-size: 23px;
                    line-height: 28px;
                    color: #fff;
                    font-family: $circular-book;
                    text-decoration: none;

                    &.active {
                      color: #000;
                      text-decoration: none;
                      font-family: $circular-bold;
                    }

                    &:hover {
                      color: #000;
                    }

                    @media (max-width: 767px) {
                      font-size: 20px;
                      line-height: 24px;
                    }
                  }

                  & + li {
                    margin-top: 20px;

                    @media (max-width: 767px) {
                      margin-top: 15px;
                    }
                  }
                }
                & + ul {
                  margin-top: 50px;
                }
              }
            }
          }
          .sidebar-upper-wrap {
            margin-top: 85px;
          }
          .sidebar-lower-wrap {
            margin-top: 50px;
          }
          .sidebar-lower-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            .user-info {
              flex-grow: 1;

              a {
                color: #fff;
                font-size: 15px;
                line-height: normal;
                display: flex;
                align-items: center;
                padding-right: 10px;

                img {
                  width: 39px;
                  height: 39px;
                  border-radius: 50%;
                }
              }
            }
            .log-out {
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      left: -220px;
      width: 220px;
      height: 100%;
      padding-top: 40px;
      &.active {
        left: 0;
        z-index: 9;
      }
    }
  }

  .content-area-wrapper {
    padding-bottom: 80px;
    padding-top: 50px;
    @media (min-width: 992px) {
      margin-left: 280px;
      width: calc(100% - 280px);
      padding-top: 0;
    }

    .content-area {
      padding: 0 40px;
      @media (max-width: 991px) {
        padding: 0 20px;
      }
      @media (max-width: 767px) {
        padding: 0 15px;
      }
    }
  }
}

//body {
//  .sidebar-wrapper {
//    max-width: 54px;
//    width: 54px;
//    padding: 0;
//    position: fixed;
//    left: 0;
//    height: 100%;
//    top: 0;
//    z-index: 2;
//
//    .sidebar {
//      .sidebar-block {
//        background: $gray1;
//        position: relative;
//        height: 100vh;
//        overflow-y: scroll;
//        scrollbar-width: none;
//        -ms-overflow-style: none;
//
//        &::-webkit-scrollbar {
//          display: none;
//        }
//
//
//        .sidebar-title-block {
//          background: $theme-blue;
//          position: relative;
//          height: 44px;
//
//          span {
//            position: absolute;
//            top: 50%;
//            left: 50%;
//            transform: translate(-50%, -50%);
//            font-size: 20px;
//            color: $white;
//          }
//        }
//
//        .sidebar-menu-block {
//          display: flex;
//          flex-direction: column;
//          justify-content: space-between;
//          height: calc(100% - 44px);
//        }
//
//        .theme-switch {
//          opacity: .7;
//        }
//
//
//        ul {
//          padding: 0;
//          list-style: none;
//          margin-top: 40px;
//          margin-bottom: 20px;
//          padding: 0 5px;
//          text-align: center;
//
//          &.lower-list {
//            //min-height: 240px;
//            display: flex;
//            flex-direction: column;
//            align-items: center;
//          }
//
//          li {
//            a {
//              font-size: 15px;
//              line-height: 18px;
//              color: $black;
//              font-family: $circular-bold;
//            }
//
//            & + li {
//              margin-top: 20px;
//            }
//
//            &.rounded-circle {
//              width: 39px;
//              height: 39px;
//              background: $theme-blue;
//              margin: 20px auto 0;
//
//              a {
//                width: 100%;
//                height: 100%;
//                display: flex;
//                align-items: center;
//                justify-content: center;
//                color: $white;
//              }
//            }
//
//            &.rotated {
//              transform: rotate(-180deg);
//              white-space: nowrap;
//              text-orientation: inherit;
//              writing-mode: vertical-rl;
//            }
//          }
//        }
//      }
//
//      .main-menu {
//        background: $white;
//        position: fixed;
//        top: 0;
//        left: 54px;
//        width: calc(100% - 54px);
//        height: 100%;
//        display: flex;
//        overflow-y: scroll;
//        opacity: 0;
//        visibility: hidden;
//        @include transition(0.3s ease all);
//        scrollbar-width: none;
//        -ms-overflow-style: none;
//
//        &::-webkit-scrollbar {
//          display: none;
//        }
//
//        .main-menu-wrapper {
//          padding: 30px 5%;
//          margin: auto 0;
//
//          @media (max-width: 1199px) {
//            padding: 30px 10%;
//          }
//
//
//          .sub-heading {
//            h6 {
//              font-size: 15px;
//            }
//          }
//
//          ul {
//            list-style: none;
//            padding: 0;
//            margin-bottom: 0;
//
//            li {
//              a {
//                font-size: 40px;
//                line-height: 44px;
//                color: $black;
//
//                &.active {
//                  color: $theme-blue;
//                  text-decoration: underline;
//                  font-family: $circular-bold;
//                }
//
//                &:hover {
//                  color: $theme-blue;
//                }
//
//                @media (max-width: 1199px) {
//                  font-size: 30px;
//                  line-height: 34px;
//                }
//
//                @media (max-width: 767px) {
//                  font-size: 20px;
//                  line-height: 24px;
//                }
//              }
//
//              & + li {
//                margin-top: 30px;
//
//                @media (max-width: 1199px) {
//                  margin-top: 24px;
//                }
//
//                @media (max-width: 767px) {
//                  margin-top: 18px;
//                }
//              }
//            }
//          }
//        }
//      }
//    }
//  }
//
//  .content-area-wrapper {
//    padding-bottom: 80px;
//    margin-left: 54px;
//    width: calc(100% - 54px);
//
//    .content-area {
//      padding: 0 40px;
//      @media (max-width: 991px) {
//        padding: 0 20px;
//      }
//      @media (max-width: 767px) {
//        padding: 0 15px;
//      }
//    }
//  }
//}

/* notification banner */

.single-notification {
  display: flex;
  position: relative;
  background: $theme-dark;
  margin: 0 -40px;
  padding: 10px 40px;

  @media (min-width: 1800px) {
    // padding: 23px 40px;
  }
  @media (max-width: 991px) {
    margin: 0 -20px;
    // padding: 10px 20px;
  }

  .single-notification-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 575px) {
      display: block;
    }

    .notification-text {
      width: 100%;
      @media (max-width: 1799px) {
        width: calc(100% - 220px);
      }

      @media (max-width: 575px) {
        width: 100%;
      }

      p {
        color: $white;
        margin-bottom: 0;
        @media (min-width: 992px) {
          font-size: 20px;
          line-height: 24px;
        }

        & + p {
          margin-top: 0;
        }
      }
    }

    .button-wrapper {
      @media (min-width: 1800px) {
        position: absolute;
        top: 13px;
        right: 40px;
      }

      @media (max-width: 575px) {
        margin-top: 10px;
      }

      .btn-theme {
        border-radius: 7px;
        color: $white;
        border-color: $white;
        min-width: 140px;
        @media (min-width: 992px) {
          padding: 13px 20px;
          font-size: 18px;
          min-width: 164px;
        }
      }
    }
  }

  &.error-notification {
    background: $red;
  }

  &.process-notification {
    background: #0d21e3;
  }

  &.session-expired {
    .single-notification-wrapper {
      .notification-text {
        p {
          font-size: 16px;
        }
      }
    }
  }

  & + .single-notification {
    margin-top: 25px;
  }
}

/* heading block */

.header-block {
  display: flex;
  position: relative;
  margin-top: 25px;

  &.mt-90 {
    margin-top: 90px;
  }

  @media (max-width: 767px) {
    margin-top: 15px;
  }

  .header-block-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (max-width: 575px) {
      display: block;
    }

    .search-bar {
      position: relative;
      width: 100%;
      @media (max-width: 1799px) {
        width: calc(100% - 200px);
      }

      @media (max-width: 575px) {
        width: 100%;
      }

      span.icon-search {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        color: transparentize($black, 0.43);
        font-size: 20px;
        @media (max-width: 991px) {
          right: 15px;
        }
      }

      input {
        width: 100%;
        border: none;
        border-bottom: 1px solid transparentize($theme-dark, 0.8);
        border-radius: 0;
        height: 45px;
        padding: 10px 60px 10px 30px;
        background: transparent;

        @media (max-width: 991px) {
          padding: 10px 60px 10px 15px;
        }

        &::placeholder {
          color: transparentize($black, 0.61);
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: transparentize($black, 0.61);
        }

        &::-ms-input-placeholder {
          color: transparentize($black, 0.61);
        }
      }
    }

    .button-wrapper {
      @media (min-width: 1800px) {
        position: absolute;
        top: 0;
        right: 0;
      }

      @media (max-width: 575px) {
        margin-top: 10px;
      }

      .btn-theme {
        border-radius: 7px;
        min-width: 140px;
        @media (min-width: 992px) {
          padding: 13px 20px;
          font-size: 18px;
          min-width: 164px;
        }
      }
    }
  }
}

/* billing error */

.billing-error {
  .upper-block {
    padding: 9px 30px;
    background: $pink;
    border-radius: 6px 6px 0 0;

    p {
      font-size: 15px;
      line-height: 21px;
      color: $white;
      font-family: $circular-bold;
    }
  }

  .lower-block {
    background: #f7f7f7;
    padding: 15px 30px;
    border-radius: 0 0 6px 6px;
  }

  .upper-block,
  .lower-block {
    @media (max-width: 991px) {
      padding-right: 15px;
      padding-left: 15px;
    }
    @media (min-width: 576px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .text-block {
    margin-bottom: 10px;
    @media (min-width: 576px) {
      width: calc(100% - 220px);
      margin-bottom: 0;
    }
  }

  .misc-block {
    @media (min-width: 576px) {
      p {
        padding: 0 20px;
      }
    }
  }

  p {
    margin-bottom: 0;

    & + p {
      margin-top: 0;
    }
  }
}

/* boxed block */

.boxed-block {
  max-width: 1280px;
  margin: 0 auto;
}

/* heading block */

.heading-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 5px 5px 30px;
  border-bottom: 1px solid transparentize($theme-dark, 0.8);

  @media (max-width: 991px) {
    padding: 10px 5px 5px 15px;
  }

  h2 {
    margin-bottom: 5px;
    padding-right: 20px;
  }

  span {
    font-size: 15px;
    line-height: 18px;
    font-family: $circular-bold;
    color: $gray2;
    margin-bottom: 5px;
  }
}

/* content menu */

.content-menu {
  padding: 20px 5px 10px 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding: 15px 5px 10px 15px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    @media (min-width: 768px) {
      padding-right: 30px;
    }

    li {
      display: inline-block;
      margin-right: 30px;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        margin-right: 12px;
      }

      a {
        font-size: 18px;
        color: $black;
        padding: 10px 0;

        &.active {
          background: $theme-blue;
          color: $white;
          font-family: $circular-bold;
          padding: 10px 30px;
          border-radius: 50px;
        }

        &:not(.active) {
          &:hover {
            color: $theme-blue;
          }
        }

        @media (max-width: 767px) {
          font-size: 14px;
          padding: 6px 0;
          &.active {
            padding: 6px 15px;
          }
        }
      }
    }
  }

  .danger-zone {
    a {
      font-size: 18px;
      color: transparentize($black, 0.82);
      @media (max-width: 767px) {
        font-size: 14px;
      }
      &:hover {
        color: $red;
      }
    }
  }
}

/* nav pills */

.nav-pills {
  //border-bottom: 1px solid transparentize($black, .94);
  padding-bottom: 20px;
  margin-bottom: 20px;
  border: none !important;
  cursor: pointer;

  li.nav-item {
    margin-right: 30px;
    @media (max-width: 767px) {
      margin-right: 15px;
    }

    &:last-child {
      margin-right: 0;
    }

    a.nav-link {
      padding: 10px 0;
      color: $black;
      font-size: 16px;
      transition: none;

      @media (max-width: 767px) {
        padding: 6px 0;
        font-size: 14px;
      }

      &.active {
        background: $theme-blue;
        color: $white;
        font-family: $circular-bold;
        padding: 10px 30px;
        border-radius: 50px;

        @media (max-width: 767px) {
          padding: 6px 16px;
        }
      }
      &:not(.active) {
        &:hover {
          color: $theme-blue;
        }
      }
    }
  }
}

/* dropdown menu */
.dropdown-menu {
  @include border-radius(0);
  border: none;
  box-shadow: 0 3px 6px transparentize($black, 0.57);
  padding: 0;

  a {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 12px 20px;
    text-align: center;

    & + a {
      border-top: 1px solid transparentize($black, 0.94);
    }

    &:active,
    &:focus {
      background: transparent;
      outline: none !important;
      color: $black;
    }

    &:hover {
      background: transparent;
      color: $theme-blue;
      font-weight: 600;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 2px;
    left: -webkit-calc(50% + 30px);
    left: calc(50% + 30px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    @include rotate(135);
    @include transform-origin(0 0);
    box-shadow: -2px 2px 0 -1px rgba(0, 0, 0, 0.15);
  }
}

/* fixed footer */

.fixed-footer {
  position: fixed;
  padding: 20px 40px;
  bottom: 0;
  width: calc(100% - 54px);
  text-align: right;
  background: $theme-default;
  right: 0;
  @media (max-width: 991px) {
    padding: 15px;
    display: none;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.07;
    font-family: $circular-bold;
    margin-bottom: 0;
  }
}

/* pop-up messages */

.pop-up-messages-block {
  position: absolute;
  margin-top: 30px;
  right: 40px;
  width: 100%;
  max-width: 400px;
  @media (max-width: 991px) {
    right: 20px;
  }
  @media (max-width: 500px) {
    margin-top: 15px;
    max-width: none;
    width: calc(100% - 94px);
  }
}

.pop-up-messages {
  background: $white;
  box-shadow: 0 3px 6px transparentize($black, 0.84);
  border-radius: 2px;
  padding: 15px;

  .media {
    .media-img {
      width: 36px;

      svg {
        width: 100%;
      }
    }

    .media-body {
      h6 {
        font-size: 16px;
        margin-bottom: 4px;
        color: $black;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
        color: $black;
      }
    }
  }
}

/* bg colors */

.light-gray-bg {
  background: transparentize($gray1, 0.77);
}

.white-bg {
  background: $white;
}

/* form */

.form-group {
  margin-bottom: 20px;

  label {
    font-size: 16px;
    line-height: 19px;
    font-family: $circular-medium;
    padding: 0 12px;
  }

  .form-control {
    background-color: transparent;
    color: $black;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $gray5;

    &:focus {
      background-color: transparent;
      border-bottom-color: $theme-blue;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 40px;
    background: url("../../images/arrow-blue.png") no-repeat;
    background-position: calc(100% - 15px) 50%;
  }

  textarea {
    height: 220px;

    &::placeholder {
      transform: translateY(180px);
    }
  }

  .wp-password-input-wrapper {
    position: relative;

    input {
      padding-right: 40px;
    }

    .reset {
      position: absolute;
      color: $theme-blue;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

/* custom input range */

.range-wrap {
  position: relative;
  margin-top: 15px;
  .range-value {
    position: absolute;
    top: -50%;
    span {
      width: 30px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 12px;
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 6px;
    }
  }
  input[type="range"] {
    //-webkit-appearance: none;
    -moz-appearance: none;
    margin: 16px 0 10px;
    width: 100%;
    background: $gray5;
    padding: 0;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
    height: 4px;

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      -webkit-appearance: none;
    }

    @supports (-ms-ime-align: auto) {
      background-image: none !important;
      height: 25px;
      margin: 5px 0 10px 0;
      background: transparent;
    }

    @media screen and (min-width: 0 \0) and (min-resolution: +72dpi) {
      background-image: none !important;
      height: 25px;
      margin: 5px 0 10px 0;
      background: transparent;
    }

    &:focus {
      outline: none;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 4px;
      cursor: pointer;
      animate: 0.2s;
      border-radius: 25px;
    }
    &::-webkit-slider-thumb {
      height: 19px;
      width: 19px;
      border-radius: 50%;
      background: $theme-blue;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -8px;
      border: none;
    }
    &::-moz-range-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      -moz-border-radius: 50%;
      height: 19px;
      width: 19px;
      border-radius: 50%;
      background: $theme-blue;
      border: none;
    }
    &::-ms-track {
      width: 100%;
      height: 4px;
      /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
      background: transparent;
      /*leave room for the larger thumb to overflow with a transparent border */
      border-color: transparent;
      border-width: 2px 0;
      /*remove default tick marks*/
      color: transparent;
    }

    &::-ms-thumb {
      height: 19px;
      width: 19px;
      border-radius: 50%;
      background: $theme-blue;
      border: none;
      margin-top: 0;
    }
    &::-ms-fill-lower {
      background: #0d21e3;
    }
    &::-ms-fill-upper {
      background: #d1d1d1;
    }
  }
}

.range-length {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  span {
    font-size: 10px;
    line-height: 12px;
    color: #898989;
  }
}

/* custom classes */

.mb-mt-35 {
  @media (min-width: 992px) {
    margin-top: 30px;
  }
}

.xl-pl-60 {
  @media (min-width: 1200px) {
    padding-left: 60px !important;
  }
}

table.mb-50 {
  @media (max-width: 1199px) {
    margin-bottom: 35px;
  }
}

// new changes
.Toastify__toast--error {
  background: $white !important;
  padding: 20px !important;

  .pop-up-message {
    .media-body {
      h6,
      p {
        color: #d80000;
      }
    }
  }

  .media {
    align-items: center;
  }

  svg {
    fill: #000 !important;
  }
}

.fixed-dropdown-btn {
  @media (min-width: 768px) {
    overflow: visible !important;
  }

  table.table-with-action-buttons {
    margin-bottom: 0;
    @media (max-width: 767px) {
      margin-bottom: 80px;
    }
  }
}

.payment-block {
  @media (max-width: 767px) {
    position: relative;
  }
  .button-block {
    .dropdown {
      @media (max-width: 767px) {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    button {
      @media (max-width: 767px) {
        margin-left: 0 !important;
      }
    }
  }
}

.notification-banner {
  width: calc(100% - 280px);
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  padding: 0 40px;

  @media (max-width: 991px) {
    width: 100%;
    padding: 0;
    margin-top: 40px;
  }
}

.no-active-card {
  margin-bottom: -35px;
}
.swal-overlay {
  background-color: rgba(255, 255, 255, 0.7);

  .swal-modal {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
    border: none;
    padding: 50px 40px;
    margin: 0 auto;
    width: 100%;
    max-width: 480px;

    .swal-text {
      font-size: 16px;
      line-height: 22px;
      font-family: Circular-Book;
      margin: 0 0 8px 0;
      padding: 0;
      color: $black;
    }

    .swal-footer {
      text-align: center;
      margin: 20px 0 0 0;
      padding: 0;

      .swal-button-container {
        margin: 0 5px 0 0;

        &:last-child {
          margin-right: 0;
        }

        .swal-button {
          &--cancel {
            @extend .btn-theme;
            @extend .btn-blue;
            min-width: 162px;
          }

          &--catch {
            @extend .btn-theme;
            @extend .red-btn;
            min-width: 162px;
          }
        }
      }
    }

    .modal-body {
      padding: 50px 40px;
      h2 {
        text-align: center;
        font-size: 42px;
        line-height: 44px;
        @media (max-width: 991px) {
          font-size: 28px;
          line-height: 34px;
        }
      }
      .form-block {
        margin-top: 50px;
        h3 {
          font-size: 12px;
          line-height: 16px;
          font-family: $circular-medium;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
        label {
          padding: 0;
          font-size: 12px;
          line-height: 16px;
          font-family: $circular-book;
          margin-bottom: 0;
        }
        input {
          height: 30px;
          padding: 5px 0;
        }
      }
      p {
        text-align: center;
      }
      .btn-theme {
        @media (min-width: 768px) {
          font-size: 18px;
        }
        min-width: 162px;
        margin-bottom: 10px;
      }
      .remove-card-wrapper {
        padding: 15% 0;
      }
    }
  }
}

.custom-popup {
  padding: 0;

  .modal-content {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.16);
    border: none;
  }
  .modal-body {
    padding: 50px 40px;
    h2 {
      text-align: center;
      font-size: 42px;
      line-height: 44px;
      @media (max-width: 991px) {
        font-size: 28px;
        line-height: 34px;
      }
    }
    .form-block {
      margin-top: 50px;
      h3 {
        font-size: 12px;
        line-height: 16px;
        font-family: $circular-medium;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      label {
        padding: 0;
        font-size: 12px;
        line-height: 16px;
        font-family: $circular-book;
        margin-bottom: 0;
      }
      input {
        height: 30px;
        padding: 5px 0;
      }
    }
    p {
      text-align: center;
    }
    .btn-theme {
      @media (min-width: 768px) {
        font-size: 18px;
      }
      min-width: 162px;
      margin-bottom: 10px;
    }
    .remove-card-wrapper {
      padding: 15% 0;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.text-block-wrap {
  p {
    & + p {
      margin-top: 15px;
    }
  }
}

.bg-blue {
  background: #0d21e3 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

//tools-table
.tools-table {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            min-width: 300px;
          }
        }
      }
    }
  }
}

//button-group
.button-group {
  .btn-theme {
    margin-right: 28px !important;

    @media (max-width: 767px) {
      margin-right: 10px !important;
      min-width: 130px !important;
    }

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

//btn
.action-pay {
  z-index: 1;
}

body {
  .login {
    .sidebar-wrapper {
      max-width: 54px;
      width: 54px;
      padding: 0;
      position: fixed;
      left: 0;
      height: 100%;
      top: 0;
      z-index: 2;

      @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        height: 44px;
      }

      .sidebar {
        .sidebar-block {
          background: $gray1;
          position: relative;
          height: 100vh;
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;

          &::-webkit-scrollbar {
            display: none;
          }

          @media (max-width: 767px) {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .sidebar-title-block {
            background: $theme-blue;
            position: relative;
            height: 44px;
            @media (max-width: 767px) {
              width: 54px;
            }

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 20px;
              color: $white;
            }
          }

          .sidebar-menu-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 44px);
            padding-top: 20px;

            @media (max-width: 767px) {
              justify-content: center;
              align-items: center;
              height: 44px;
              padding: 5px 20px;
            }
          }

          .theme-switch {
            opacity: 0.7;
          }
        }
      }
    }

    .content-area-wrapper {
      padding-bottom: 0px;
      margin-left: 54px;
      width: calc(100% - 54px);
      padding-top: 44px;
      @media (min-width: 768px) {
        padding-top: 0;
      }

      @media (max-width: 767px) {
        margin-left: 0;
        width: 100%;
      }

      .content-area {
        .login-page-block {
          .form-block {
            padding: 30px 24px;
          }
        }
      }
    }
  }
}
.bg-img {
  .left-img,
  .right-img {
    pointer-events: none;
  }
}

.menu-switch-block {
  cursor: pointer;
}

.feedback-btn {
  background: transparent !important;
  color: $black !important;
  transition: 0.3s ease all;
  z-index: 2;
  font-size: 23px !important;
  font-family: $circular-book !important;
  text-shadow: none !important;
  padding: 0 0 0 30px !important;
  box-shadow: none !important;

  @media (max-width: 991px) {
    padding: 0 !important;
  }

  @media (max-width: 767px) {
    font-size: 20px !important;
  }

  &::before {
    content: none !important;
  }
  &:hover {
    color: #0d21e3 !important;
  }
}

.language-selection {
  position: relative;
  display: flex;
  cursor: pointer;
  height: 30px;
  padding-left: 30px;
  overflow-y: none;
  list-style: none;

  .language {
    background: none;
    font-size: 23px !important;
    cursor: pointer;
    transition: 0.3s ease all;
    display: flex;
    a {
      color: #fff !important;
      &:hover {
        color: #000 !important;
      }
    }
  }

  .language:hover {
    color: #fff !important;
  }

  .chevron {
    width: 15px;
    height: 15px;
    background: white;
    position: absolute;
    z-index: 201;
    transform: rotate(45deg);
    left: 143.5px;
    bottom: 4px;
    border-left: lightgray 1px solid;
    border-bottom: lightgray 1px solid;
  }

  #chevron-sv {
    left: 105.5px;
  }

  .options {
    width: 150px;
    position: absolute;
    z-index: 200;
    background: white;
    box-shadow: 0px 0px 10px 5px #bebebe;
    left: 151px;
    bottom: -36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    list-style: none;
    font-size: 18px;
    border: lightgray 1px solid;
    border-radius: 5px;

    div {
      border-bottom: solid lightgray 1px;
    }
    li {
      padding: 10px 0 10px 20px;
      transition: 0.3s;
    }

    li:hover {
      color: #3c3cff;
    }

    #current-language {
      color: blue;
    }

    #current-language:hover {
      color: #3c3cff;
    }
  }

  #options-sv {
    left: 113px;
  }
}

.intercom-lightweight-app-launcher {
  bottom: 80px !important;
}

.text-primary {
  color: $theme-blue !important;
}

.support-page {
  p {
    &.text-underline {
      text-decoration: underline;
    }
  }
  h6 {
    font-size: 15px;
    &.mb-0 {
      margin-bottom: 0px;
    }
  }
}
