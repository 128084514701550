.modal-overlay {
  background-color: rgba(255,255,255,.7);
}
.custom-popup {
  max-width: 510px;
  box-shadow: 0 3px 18px  rgba(0,0,0,.16);
  border: none;
  border-radius: 20px;
  //[class*="styles_closeButton__"] {
  //  top: -20px;
  //  right: -20px;
  //}

  .form-group {
    label {
      font-size: 12px;
      font-family: $circular-book;
      color: $gray6;
      margin-bottom: 12px;
      padding: 0;
    }

    input {
      border-bottom: 1px solid $gray7;
      font-size: 16px;
      color: $black2;
      padding: 0;
    }
  }

  .form-check {
    padding: 0;

    label {
      position: relative;
      padding-left: 30px;
      transition: 0.3s ease all;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        transition: 0.3s ease all;
      }

      &::before {
        width: 20px;
        height: 20px;
        left: 0;
        border: 1px solid $gray7;
      }

      &::after {
        width: 14px;
        height: 14px;
        left: 3px;
        top: 3px;
        background: $theme-blue;
        transform: scale(0.7);
        visibility: hidden;
        opacity: 0;
      }
    }

    input {
      display: none;

      &:checked {
        + label {
          &::before {
            border: 1px solid $theme-blue;
          }

          &::after {
            transform: scale(1);
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .add-new-card {
    padding: 30px 0 40px 0;

    .heading-block {
      padding: 0;
      border: none;
      justify-content: center;

      .title-block {
        h2 {
          padding: 0;
          margin: 0;
        }
      }
    }

    .payment-details {
      form {
        > h3 {
          color: $black2;
          margin-bottom: 30px;
        }

        .card-detail-container {
          .form-group {
            .card-info-wrapper {
              label {
                + div {
                  border-bottom: 1px solid $gray7;
                  padding-bottom: 5px;
                  .StripeElement {
                    border-bottom: 2px solid #d1d1d1;
                    padding: 6px 12px;
                  }
                }
              }
            }
          }

          .btn-holder {
            margin-top: 40px;
            margin-bottom: 0;

            button {
              padding: 13px 14px;
              min-width: 164px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}