// Theme ../font family variables

$circular-bold : "Circular-Bold";
$circular-book : "Circular-Book";
$circular-medium : "Circular-Medium";
$courier: "Courier-New-Regular";





// Theme ../Color variables

$theme-dark  :  #0E1226;

$theme-default  :  #ffffff;

$theme-blue  : #0D21E3;

$white      : #ffffff;

$black      : #000000;

$black1      : #1A293B;

$black3      : #1c1e2c;

$black2      : #333333;

$gray      : #cec6c6;

$light-gray      : #f1f1f1;

$gray1      : #F4F4F5;

$gray2      : #D3D3D3;

$gray3      : #F8F8F8;

$gray4      : #E0E0E0;

$gray5      : #D1D1D1;

$gray6      : #666666;

$gray7      : #E6E6E6;

$blue1      : #051439;

$blue2      : #1A1F36;

$blue3      : #03050A;

$red      : #D80000;

$pink      : #ED473E;