//Font Family
@font-face {
  font-family: 'Circular-Bold';
  src: url('../../fonts/CircularStd-Bold.eot');
  src: url('../../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Bold.woff2') format('woff2'),
  url('../../fonts/CircularStd-Bold.woff') format('woff'),
  url('../../fonts/CircularStd-Bold.ttf') format('truetype'),
  url('../../fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Book';
  src: url('../../fonts/CircularStd-Book.eot');
  src: url('../../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Book.woff2') format('woff2'),
  url('../../fonts/CircularStd-Book.woff') format('woff'),
  url('../../fonts/CircularStd-Book.ttf') format('truetype'),
  url('../../fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('../../fonts/CircularStd-Medium.eot');
  src: url('../../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CircularStd-Medium.woff2') format('woff2'),
  url('../../fonts/CircularStd-Medium.woff') format('woff'),
  url('../../fonts/CircularStd-Medium.ttf') format('truetype'),
  url('../../fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Courier-New-Regular';
  src: url('../../fonts/CourierNewPSMT.eot');
  src: url('../../fonts/CourierNewPSMT.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/CourierNewPSMT.woff2') format('woff2'),
  url('../../fonts/CourierNewPSMT.woff') format('woff'),
  url('../../fonts/CourierNewPSMT.ttf') format('truetype'),
  url('../../fonts/CourierNewPSMT.svg#CourierNewPSMT') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kepler-customer';
  src:  url('../../fonts/kepler-customer.eot');
  src:  url('../../fonts/kepler-customer.eot#iefix') format('embedded-opentype'),
  url('../../fonts/kepler-customer.ttf') format('truetype'),
  url('../../fonts/kepler-customer.woff') format('woff'),
  url('../../fonts/kepler-customer.svg#kepler-customer') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'kepler-customer' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e900";
}
.icon-hbg:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-minus:before {
  content: "\e904";
}
.icon-copy:before {
  content: "\e905";
}
.icon-more:before {
  content: "\e906";
}
.icon-down:before {
  content: "\e907";
}
.icon-redo:before {
  content: "\e908";
}
.icon-edit:before {
  content: "\e909";
}
.icon-share:before {
  content: "\e910";
}
.icon-close-circle:before {
  content: "\e911";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-pending:before {
  content: "\e912";
}