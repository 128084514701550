.register-page-block {
  .title-block {
    margin-bottom: 50px;

    span {
      font-size: 24px;
    }
  }
  .form-block {
    max-width: 796px;

    @media (max-width: 1680px) {
      max-width: 675px;
    }
  }
  .register-nav-menu {
    position: relative;

    &:before {
      content: '';
      width: calc(100% - 100px);
      height: 2px;
      background: #0d22e3;
      display: block;
      margin: 0 auto;
      left: 50%;
      top: 12px;
      position: absolute;
      right: 0;
      transform: translateX(calc(-50% - 8px));
    }

    ul {
      &.register-nav-link {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          position: relative;
          &::before {
            content: '';
            width: 26px;
            height: 26px;
            display: block;
            border: 2px solid $theme-blue;
            background: $white;
            border-radius: 50px;
            margin: 0 auto;
          }

          a {
            font-size: 15px;
            font-family: $circular-book;
            color: $black3;
          }

          &.current,
          &.completed{
            &::before {
              background: $theme-blue;
            }

            a {
              font-family: $circular-bold;
            }
          }
        }
      }
    }
  }

  form {
    .form-row {
      > .col,
      > [class*="col-"] {
        padding: 0 15px;
      }
    }
    .form-group {
      label {
        padding: 0 10px;

        @media (max-width: 1680px) {
          margin-bottom: 0;
        }
      }
      .form-control {
        background: transparent;
        padding: 0 10px;
        border-bottom: 2px solid $gray5;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $theme-blue;
        }
      }
    }
  }

  .company-question {
    background: $gray1;
  }

  .steps-buttons {
    button {
      margin-bottom: 15px;
      @media (max-width: 767px) {
        min-width: 140px;
      }
      @media (max-width: 360px) {
        min-width: 100%;
        margin-left: 0 !important;
      }
    }
  }

  @media (max-width: 767px) {
    height: auto !important;
    overflow: auto !important;
  }
}

.company-action {
  background: $gray1;
  padding: 19px 42px 21px 65px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h6 {
    margin-bottom: 0;
    font-family: $circular-book;
  }
}