.form-control-color-dark-mode {
  color: $white;

  &::placeholder {
    color: transparentize($white, 0.86);
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: transparentize($white, 0.86);
  }

  &::-ms-input-placeholder {
    color: transparentize($white, 0.86);
  }
}

body {
  &.dark-theme {
    background: $theme-dark;
    color: $white;

    .theme-switch {
      .theme-toggle-btn {
        &::before {
          z-index: 0;
          left: 12px;
        }

        &::after {
          left: 0;
          z-index: 1;
        }
      }
    }

    .nav-content-header-search {
      input {
        color: $white;
        border-bottom: solid 0.3px $white !important;
      }
    }

    .add-new-card .company-question {
      background: #1a1f36;
    }

    header {
      background: #0e1226;

      .brandLogo {
        a {
          p {
            color: $white;
          }
        }
      }

      .navbar-toggle-btn {
        span {
          background: $white;
        }
      }
    }

    .btn-theme {
      color: $white;
      border-color: $white;

      &:hover {
        border-color: $theme-blue;
      }

      &.btn-blue {
        border-color: $theme-blue;

        &:hover {
          background: transparent;
          border-color: $white;
          color: $white;
        }
      }

      &.btn-red {
        border-color: $red;
      }
    }

    .btn-theme-disabled {
      background: #3c3c3c;
      border-color: #3c3c3c;
      color: #8b8b8b;
    }

    .feedback-btn {
      color: $white !important;

      &:hover {
        color: #0d21e3 !important;
      }
    }

    .wp-password-input-wrapper {
      a {
        span {
          color: $white;
        }
      }
    }

    a.open-admin {
      color: $white;

      &:after {
        background: $white;
      }
    }

    .sidebar-wrapper {
      .sidebar {
        .sidebar-block {
          background: $blue2;

          .sidebar-menu-block {
            .main-menu {
              .main-menu-wrapper {
                span {
                  cursor: default;
                  font-size: 8px;
                  color: #fff;
                  @media (min-width: 992px) {
                    padding-left: 30px;
                  }
                }
                ul {
                  li {
                    a {
                      color: $white;

                      &.active {
                        color: #000;
                      }

                      &:hover {
                        color: #000;
                      }
                    }
                  }
                }
              }
            }

            .sidebar-lower-block {
              .user-info {
                a {
                  color: $white;
                }
              }

              .log-out {
                a {
                  color: $white;
                }
              }
            }

            .language-selection {
              color: $white !important;

              .language {
                transition: 0.3s ease all;

                a {
                  color: white !important;

                  &:hover {
                    // color: #0d21e3 !important;
                    color: #000 !important;
                  }
                }
              }

              .chevron {
                background: #1a1f36;
                border-left: #838383 1px solid;
                border-bottom: #838383 1px solid;
              }

              .options {
                background: #1a1f36;
                box-shadow: 0px 0px 10px 5px #252525;
                border: #838383 1px solid;

                div {
                  border-bottom: solid #838383 1px;
                }
              }
            }
          }
        }
      }
    }

    .hamburger-menu {
      .navbar-toggle-btn {
        span {
          background-color: $white;
        }
      }
    }

    .login-page-block {
      .bg-img {
        .left-img,
        .right-img {
          opacity: 0.4;
        }
      }

      .form-block {
        .upper-block {
          i {
            color: $white;
          }

          h3 {
            color: $white;
          }
        }

        label {
          color: $white;
        }

        .form-control {
          color: $black;
        }
      }

      &.page_login {
        .form-block {
          .form-control {
            @extend .form-control-color-dark-mode;
          }
        }
      }

      &.forget_password {
        form {
          .form-group {
            .form-control {
              @extend .form-control-color-dark-mode;
            }
          }
        }
      }

      &.register-page-block {
        .register-nav-menu {
          ul {
            &.register-nav-link {
              li {
                a {
                  color: $white;
                }
              }
            }
          }
        }

        .form-block {
          label {
            color: $white;
          }

          .form-control {
            @extend .form-control-color-dark-mode;
          }
        }

        .company-question {
          background: #1a1f36;
        }
      }
    }

    .single-notification {
      background: $theme-blue;

      .single-notification-wrapper {
        .button-wrapper {
          .btn-theme {
            &:hover {
              background: $theme-dark;
            }
          }
        }
      }

      &.error-notification {
        background: $red;
      }

      &.session-expired {
        .single-notification-wrapper {
          .notification-text {
            p {
              font-size: 16px;
            }
          }
        }
      }

      & + .single-notification {
        margin-top: 25px;
      }
    }

    .header-block {
      .header-block-wrapper {
        .search-bar {
          span.icon-search {
            color: transparentize($white, 0.07);
          }

          input {
            border-bottom: 1px solid transparentize($theme-blue, 0.8);
            color: $white;

            &::placeholder {
              color: transparentize($white, 0.07);
            }

            &:-ms-input-placeholder {
              color: transparentize($white, 0.07);
            }

            &::-ms-input-placeholder {
              color: transparentize($white, 0.07);
            }
          }
        }
      }
    }

    .billing-error {
      .lower-block {
        background: $black;
      }
    }

    .heading-block {
      border-bottom: 1px solid transparentize($white, 0.8);
    }

    .content-menu {
      ul {
        li {
          a {
            color: $white;

            &:not(.active) {
              &:hover {
                color: $theme-blue;
              }
            }
          }
        }
      }

      .danger-zone {
        a {
          color: transparentize($white, 0.82);

          &:hover {
            color: $red;
          }
        }
      }
    }

    .nav-pills {
      border-color: transparentize($white, 0.94);

      li.nav-item {
        a.nav-link {
          color: $white;
        }
      }
    }

    .content-container {
      background: $theme-dark;

      // added by Oskar
      .cache-content {
        background: $theme-dark;
      }

      .content-info {
        background: $theme-dark;

        .action-btn-copy {
          button {
            border-left: 1px solid transparentize($white, 0.91);

            span[class*="icon-"] {
            }
          }
        }
      }

      hr {
        border-top: 1px solid transparentize($theme-blue, 0.87);
      }

      .organization-card {
        background: transparentize($blue3, 0.77);
        border: 1px solid transparentize($white, 0.75);
      }

      .user-card {
        border: 1px solid transparentize($white, 0.75);
        color: transparentize($white, 0.21);
      }

      .add-new-user-section {
        input {
          color: $white;
        }
      }
    }

    .listing-table {
      .clear-cache,
      .ignore-rules,
      .add-new-ssl-rule,
      .migration-rules {
        background: transparentize($blue3, 0.77);
      }

      .listing-ssl {
        .custom-ssl {
          background: transparentize($blue3, 0.77);
        }
      }

      .ssl-form {
        margin-top: 10px;
        background: transparentize($blue3, 0.77);
        width: 100%;
        padding: 50px 20px 50px 40px;
      }

      .ignore-rules-buttons a {
        color: $white;
      }

      .migration-rules-buttons a {
        color: $white;
      }

      .url-table {
        tbody {
          tr {
            background: transparentize($blue3, 0.77);
          }
        }
      }

      table {
        width: 100%;

        tbody {
          tr {
            td {
              background: transparentize($blue3, 0.77);

              .media {
              }

              span {
                &.title {
                  opacity: 0.55;
                }
              }

              ul {
                li {
                  a {
                    color: $white;
                  }
                }
              }

              &.close-button {
                &:before {
                  background: transparentize($gray4, 0.75);
                }
              }
            }

            & + tr {
              border-color: $theme-dark;
            }
          }

          .table-view-block {
            width: 200px;
            text-align: right;
          }
        }
      }

      .icon-minus {
        color: $white;
      }
    }

    .table-block {
      table {
        thead {
          tr {
            border-bottom: 1px solid transparentize($white, 0.75);
          }
        }

        tbody {
          tr {
            td {
              .icon-wrapper {
                .icon-checkmark {
                  //color: $white;
                }

                a {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }

    .overview-block {
      background: $theme-dark;

      .single-overview {
        h3 {
          a {
            color: $white;
          }
        }
      }
    }

    .single-card-details {
      border: 1px solid transparentize($white, 0.77);
      color: $white;

      .button-block {
        a {
          .icon-more {
            color: $white;
          }
        }
      }
    }

    .form-group {
      .form-control {
        color: $white;
      }

      select {
        background: url("../../images/arrow-white.png") no-repeat;
        background-position: calc(100% - 15px) 50%;

        option {
          background: $theme-dark;
        }
      }

      .wp-password-input-wrapper {
        .reset {
          color: $white;
        }
      }
    }

    .fixed-footer {
      background: $theme-dark;
    }

    .light-gray-bg {
      background: transparentize($blue3, 0.77);
    }

    .white-bg {
      background: $theme-dark;
    }

    .swal-overlay {
      //background-color: rgba(0,0,0,.7);

      .swal-modal {
        box-shadow: 0 3px 18px rgba(255, 255, 255, 0.16);
        background: $theme-dark;

        .swal-text {
          color: $white;
        }

        .swal-footer {
          .swal-button-container {
            //.swal-button {
            //  &--cancel {
            //    color: $white;
            //    border-color: $white;
            //  }
            //  &--catch {
            //    &:hover {
            //      color: $white;
            //      border-color: $white;
            //    }
            //  }
            //}
          }
        }

        .modal-body {
          padding: 50px 40px;

          h2 {
            text-align: center;
            font-size: 42px;
            line-height: 44px;
            @media (max-width: 991px) {
              font-size: 28px;
              line-height: 34px;
            }
          }

          .form-block {
            margin-top: 50px;

            h3 {
              font-size: 12px;
              line-height: 16px;
              font-family: $circular-medium;
              text-transform: uppercase;
              margin-bottom: 20px;
            }

            label {
              padding: 0;
              font-size: 12px;
              line-height: 16px;
              font-family: $circular-book;
              margin-bottom: 0;
            }

            input {
              height: 30px;
              padding: 5px 0;
            }
          }

          p {
            text-align: center;
          }

          .btn-theme {
            @media (min-width: 768px) {
              font-size: 18px;
            }
            min-width: 162px;
            margin-bottom: 10px;
          }

          .remove-card-wrapper {
            padding: 15% 0;
          }
        }
      }
    }
  }
}

body.dark-theme {
  .custom-popup {
    &.domainPopup {
      input {
        border-bottom: solid 0.3px $white !important;
        //border-bottom: 1px solid transparentize($theme-blue, .80);
        color: $white;

        &::placeholder {
          color: transparentize($white, 0.07);
        }

        &:-ms-input-placeholder {
          color: transparentize($white, 0.07);
        }

        &::-ms-input-placeholder {
          color: transparentize($white, 0.07);
        }
      }
      input:focus {
        outline: none;
        border-bottom: solid 2px #0d21e3 !important;
      }
    }
  }
}
