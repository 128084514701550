body {
  header {
    background: #000;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 10;
    left: 0;
    padding: 2px 25px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    @media (min-width: 992px) {
      display: none;
      background: $black1;
    }
    @media (max-width: 991px) {
      padding: 0;
    }

    .navbar {
      padding: 0;
    }
    .brandLogo {
      display: flex;
      align-items: center;
      margin-left: -15px;

      a {
        display: flex;
        align-items: center;
      }
      span {
        font-size: 20px;
        color: $white;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #0d21e3;
      }
      p {
        // color: $black;
        color: #fff;
        margin-bottom: 0;
        font-size: 15px;
        margin-left: 10px;
      }
    }
    .navbar-toggle-btn {
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      right: 0;
      display: inline-block;
      transition: all 200ms ease;

      span {
        display: inline-block;
        width: 100%;
        height: 3px;
        // background-color: $black;
        background-color: #fff;
        position: absolute;
        transition: all 200ms ease;
        left: 0;
        top: calc(50% - 1.5px);
        &:first-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate1;
          transform: translateY(-300%);
        }

        &:last-child {
          transform-origin: center;
          animation-duration: 600ms;
          animation-fill-mode: forwards;
          animation-name: rotate2;
          transform: translateY(300%);
        }
      }
      &.open {
        span {
          &:first-child {
            transform: translateY(0);
            animation-name: rotate-open-1;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            transform: translateY(0);
            animation-name: rotate-open-2;
          }
        }
      }
    }
  }
}

@keyframes rotate1 {
  0% {
    transform: translateY(0) rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes rotate2 {
  0% {
    transform: translateY(0) rotate(-45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: translateY(300%);
  }
}

@keyframes rotate-open-1 {
  0% {
    transform: translateY(-300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(-45deg);
  }
}

@keyframes rotate-open-2 {
  0% {
    transform: translateY(300%);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

.theme-switch {
  .theme-toggle-btn {
    width: 34px;
    height: 34px;
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: 0.3s ease all;
    }

    &::before {
      background: #ebebeb;
      z-index: 1;
    }

    &::after {
      background: #787984;
      left: 12px;
      z-index: 0;
    }
  }
}

.theme-switch-md {
  @extend .theme-switch;
}
