$grid-columns:      12;
$screen-sm-min : 768px;
$screen-md-min : 992px;
$screen-lg-min : 1200px;

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin border-radius($border_radius...) {
  -moz-border-radius: $border_radius;
  -o-border-radius: $border_radius;
  -webkit-border-radius: $border_radius;
  border-radius: $border_radius;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

@mixin transform-origin ($origin) {
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}

@mixin make-grid-offset-right($class) {
  @for $index from 0 through $grid-columns {
    .offset-#{$class}-right-#{$index} {
      margin-right: percentage(($index / $grid-columns));
    }
  }
}

@include make-grid-offset-right(xs);

@media (min-width: $sm) {
  @include make-grid-offset-right(sm);
}

@media (min-width: $md) {
  @include make-grid-offset-right(md);
}

@media (min-width: $lg) {
  @include make-grid-offset-right(lg);
}

@media (min-width: $xl) {
  @include make-grid-offset-right(xl);
}

@function rem($pixels, $context: 0.0625) {
  @return #{$pixels*$context}rem;
}

@mixin getMarginPadding($className, $styleName) {
  $step    : 5;
  $max     : 300;
  $from    : ceil(1 /$step);
  $through : ceil($max /$step);
  $unit: 'px';

  @for $i from $from through $through {
    $i: $i * $step;

    #{$className + $i} {
      #{$styleName}: #{($i) + $unit};
    }

    @if ($i >= 35) {
      @media (max-width: 1199px) {
        #{$className + $i} {
          #{$styleName}: #{($i)/2 + $unit};
        }
      }

      @media (max-width: 991px) {
        #{$className + $i} {
          #{$styleName}: #{($i)/2.1 + $unit};
        }
      }
    }
  }
}
