.btn-theme {
  color: $black1;
  font-size: 14px;
  line-height: 16px;
  font-family: $circular-bold;
  border: 1px solid $blue1;
  border-radius: 50px;
  padding: 11px 20px;
  display: inline-block;
  text-align: center;
  background: transparent;

  &:hover {
    background: $theme-blue;
    color: $white;
    border-color: $theme-blue;
  }
  &.btn-blue {
    background: $theme-blue;
    color: $white;
    border-color: $theme-blue;
    &:hover {
      background: transparent;
      color: $black1;
      border-color: $blue1;
    }
  }
  &.btn-lg {
    min-width: 214px;
  }

  &.btn-big {
    padding: 13px 30px;
    font-size: 18px;
  }

  &.btn-restore {
    &:after {
      display: none;
    }
  }
  &.btn-dashed {
    border: 1px dashed $gray;
  }

  &.btn-red {
    border-color: $red !important;
  }

  &.full-btn {
    min-width: 100%;
  }

  &.red-btn {
    background: $red !important;
    border-color: $red !important;
    color: $white;
    transition: 0.3s ease all;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (max-width: 767px) {
    padding: 9px 20px;
  }
}

.btn-theme-disabled {
  color: #d1d1d1;
  font-size: 14px;
  line-height: 16px;
  font-family: $circular-bold;
  border: 1px solid $light-gray;
  border-radius: 50px;
  padding: 11px 20px;
  display: inline-block;
  text-align: center;
  background: $light-gray;
  &.btn-lg {
    min-width: 162px;
  }

  &.btn-big {
    padding: 13px 30px;
    font-size: 18px;
  }
}

a.open-admin {
  font-size: 14px;
  color: $black;
  font-family: $circular-bold;
  position: relative;
  margin: 9px 0;
  &:after {
    content: "";
    height: 1px;
    position: absolute;
    width: calc(100% - 18px);
    bottom: 4px;
    left: 0;
    background: $black;
  }
  .icon-share {
    font-size: 10px;
    margin-left: 6px;
    font-weight: 600;
  }
}

.inline-buttons {
  .btn-theme {
    margin-right: 12px;
    margin-bottom: 12px;
    display: inline-block;
    &:last-child {
      margin-right: 0;
    }
  }
  &.text-center {
    .btn-theme {
      margin-left: 6px;
      margin-right: 6px;
    }
  }
}

.single-notification-wrapper {
  .button-wrapper {
    .btn-theme {
      &:hover {
        color: $black !important;
        background: $white;
      }
    }
  }
}
